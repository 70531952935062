/** @jsx jsx */
import { jsx, Box, Button, Card, Container, Flex, Styled, Text } from "theme-ui"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  DonorStoryGrid,
  HeroGradient,
  PatientStoryGrid,
  SectionHeading,
  VideoPlayer,
} from "../../components/elements"

const Giving = ({ data, pageContext }) => {
  const page = data.datoCmsGiving
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
            </HeroGradient>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
          </BackgroundImage>
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ pt: 4, width: ["full", "full", "4/5"] }}>
                <VideoPlayer url={page.video.url} />
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* WaysToGive */}
        <Box as="section" id="ways">
          <Container sx={{ px: "1em" }}>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box sx={{ width: "full", textAlign: "center", mb: 2 }}>
                <SectionHeading>Ways you can Give</SectionHeading>
              </Box>
            </Flex>
          </Container>
          <Flex sx={{ flexWrap: "wrap", color: "white" }}>
            {data.allDatoCmsWaysGive.edges
              .map(({ node }) => (
                <BackgroundImage
                  Tag="div"
                  fluid={node.heroImage.fluid}
                  key={node.id}
                  sx={{
                    alignItems: ["flex-start", "flex-start", "flex-end"],
                    bg: "children.2",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: [4, 4, 5],
                    minHeight: "20em",
                    width: ["full", "1/2"],
                  }}
                >
                  <Box sx={{ position: "relative", zIndex: 10 }}>
                    <Styled.h3 style={{ color: "white" }}>
                      {node.title}
                    </Styled.h3>
                    <p sx={{ color: "white", maxWidth: "29em" }}>
                      {node.introContent}
                    </p>
                    <Box
                      as="a"
                      href="https://give.wvu.edu/campaign/wvu-medicine-childrens/c430998"
                    >
                      <Button
                        as="span"
                        variant="inverted"
                        sx={{
                          "&:hover": {
                            color: "children.2",
                          },
                        }}
                      >
                        {`More About ${node.title}`}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      bg: "children.2",
                      opacity: 0.8,
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      width: "full",
                      height: "full",
                      zIndex: 1,
                    }}
                  />
                </BackgroundImage>
              ))
              .shift()}

            <Flex
              sx={{
                flexWrap: "wrap",
                flexDirection: ["column"],
                width: ["full", "1/2"],
                justifyContent: "space-between",
              }}
            >
              {data.allDatoCmsWaysGive.edges
                .map(({ node }) => (
                  <BackgroundImage
                    Tag="div"
                    fluid={node.heroImage.fluid}
                    key={node.id}
                    sx={{
                      width: ["full"],
                      bg: "children.4",
                      p: [4, 4, 5],
                    }}
                  >
                    <Box sx={{ position: "relative", zIndex: 10 }}>
                      <Styled.h3 style={{ color: "white" }}>
                        {node.title}
                      </Styled.h3>
                      <p sx={{ color: "white", maxWidth: "29em" }}>
                        {node.introContent}
                      </p>
                      <Link to={`/giving/${node.slug}`}>
                        <Button
                          as="span"
                          variant="inverted"
                          sx={{
                            "&:hover": {
                              color: "children.4",
                            },
                          }}
                        >
                          {`More About ${node.title}`}
                        </Button>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        bg: "children.4",
                        opacity: 0.9,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        width: "full",
                        height: "full",
                        zIndex: 1,
                      }}
                    />
                  </BackgroundImage>
                ))
                .splice(1, 1)}

              {data.allDatoCmsWaysGive.edges
                .map(({ node }) => (
                  <BackgroundImage
                    Tag="div"
                    fluid={node.heroImage.fluid}
                    key={node.id}
                    sx={{
                      width: ["full"],
                      bg: "children.1",
                      p: [4, 4, 5],
                    }}
                  >
                    <Box sx={{ position: "relative", zIndex: 10 }}>
                      <Styled.h3 style={{ color: "white" }}>
                        {node.title}
                      </Styled.h3>
                      <p sx={{ color: "white", maxWidth: "29em" }}>
                        {node.introContent}
                      </p>
                      <Link to={`/giving/${node.slug}`}>
                        <Button
                          as="span"
                          variant="inverted"
                          sx={{
                            "&:hover": {
                              color: "children.1",
                            },
                          }}
                        >
                          {`More About ${node.title}`}
                        </Button>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        bg: "children.1",
                        opacity: 0.9,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        width: "full",
                        height: "full",
                        zIndex: 1,
                      }}
                    />
                  </BackgroundImage>
                ))
                .splice(2, 1)}
            </Flex>
          </Flex>
        </Box>

        {/* WhyGive */}
        <Section id="why">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ width: "full", textAlign: "center", mb: 4 }}>
                <SectionHeading>Why Give</SectionHeading>
                <PatientStoryGrid />
                <br />
              </Box>
              <Box sx={{ width: "full", textAlign: "center" }}>
                <Card>
                  <DonorStoryGrid />
                </Card>
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Giving

export const query = graphql`
  {
    datoCmsGiving {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
      video {
        url
      }
    }
    allDatoCmsWaysGive(limit: 3, sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          introContent
          heroImage {
            fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          slug
          title
        }
      }
    }
  }
`
